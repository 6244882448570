import React, { useContext, useState, useEffect } from 'react'
import AuthContext from '../../../../context/Auth/Auth';
import Flight from './Components/Flight';
import Toggle from './Components/Toggle';

interface toggleInterface {
    id: number
    string: string
    selected: boolean
}

    const Booking: React.FC = () => {

        const { user } = useContext(AuthContext)

        const [ location, setLocation ] = useState<string>()
        
        useEffect(() => {

            setLocation(user?.location)

        }, [user])

        const [ toggles, setToggles ] = useState<toggleInterface[]>([
            {
                id: 0,
                string: 'Passenger',
                selected: true
            },
            {
                id: 1,
                string: 'Cargo',
                selected: true
            },
            {
                id: 2,
                string: 'Tour',
                selected: true
            },
        ])

        const selectToggle = (toggleId: number) => {

            const toggleHandler = [...toggles]

            toggleHandler[toggleId].selected = !toggleHandler[toggleId].selected

            setToggles(toggleHandler)

        }

        return (

            <div className="px-4 mt-6 sm:px-6 lg:px-8">

                <div className="grid grid-cols-3">
                    <div className="sticky top-[2rem] box-border border-2 h-[540px] mr-10 rounded p-4">
                        You are currently at <br/>
                        
                        <label className="relative block">
                            <span className="sr-only">Search</span>
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                <svg className="h-5 w-5 fill-slate-300" viewBox="0 0 20 20"></svg>
                            </span>
                            <input value={`${location}`} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLocation(e.target.value)} className="placeholder:italic placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm" placeholder="Search for anything..." type="text" name="search"/>
                        </label>

                        <div className="mt-4 font-bold">FLIGHT TYPE</div>

                        {

                            toggles.map((toggle: toggleInterface, index: number) => {

                                return (

                                    <div key={toggle.id} className="flex my-4">
                                        <Toggle selected={toggle.selected} selectToggle={selectToggle} id={index} /> <span className={`ml-2 font-bold ${toggle.selected ? 'text-slate-900' : 'text-[#e5e7eb]'}`}>{toggle.string}</span>
                                    </div>

                                )

                            })

                        }

                        <div className="mt-4 font-bold">SELECTION METHOD</div>

                    </div>
                    <div className="col-span-2">
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                        <Flight />
                    </div>
                </div>

            </div>

        )

    }

export default Booking;