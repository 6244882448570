import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";

const AppRoutes: React.FC = () => {

    return (

        <Routes>
          <Route
              path="/login"
              element={<Navigate to="/profile" replace />}
          />
          <Route path="/login">LOGIN AQUI</Route>
          <Route path="/profile">LOGIN AQUI</Route>
          <Route path="/booking">LOGIN AQUI</Route>
        </Routes>

    )

}

export default AppRoutes;