import React, { useContext, useState } from 'react'
import '../../../styling/Login.scss'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/Auth/Auth'
import { useTranslation } from 'react-i18next'

interface RegistrationInfoTypes {
    name: string
    surname: string
    email: string
    dateOfBirth: string
    country: string,
    city: string,
    preferreredAirline: string,
    vatsimId: string,
    ivaoVid: string,
}

const Register: React.FC = () => {
    
    const { t } = useTranslation()

    const [ registrationInfo, setRegistrationInfo ] = useState<RegistrationInfoTypes>({
        name: '',
        surname: '',
        email: '',
        dateOfBirth: '',
        country: '',
        city: '',
        preferreredAirline: '',
        vatsimId: '',
        ivaoVid: '',
    })

    const { DoLogin, islogging } = useContext(AuthContext)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const { name, value } = e.target

        setRegistrationInfo(prevState => ({ ...prevState, [name]: value }))

    }

    return (
        <div className="h-screen">
        <div className="min-h-full flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:min-w-[500px]">
            <div className="mx-auto w-full max-w-sm lg:w-96">
                <div className="flex justify-center">
                    <Link to={`/`}>
                        <img
                            className="h-12 w-auto"
                            src="https://virtuallh.com/vam/images/logo/dlh.png"
                            alt="login_logo"
                        />
                    </Link>
                </div>
                <h2 className="mt-10 text-[34px] font-thin text-black text-center">You are nearly there</h2>
                <p className="mt-2 text-[16px] font-thin text-[#636368] text-center">Create a free account or <Link className="hover:underline" to={'/login'}>login</Link></p>

                <div className="mt-8">

                <div className="mt-6">
                    <form>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_NAME`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="surname" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.surname} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_SURNAME`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="email" id="email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_EMAIL`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="dateOfBirth" id="dateOfBirth" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.dateOfBirth} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_DATE_OF_BIRTH`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="country" id="country" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.country} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_COUNTRY`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="city" id="city" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.city} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_CITY`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="preferreredAirline" id="preferreredAirline" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.preferreredAirline} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_PREFERRED_AIRLINE`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="vatsimId" id="vatsimId" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.vatsimId} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_VATSIM_ID`)}</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="ivaoVid" id="ivaoVid" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={registrationInfo.ivaoVid} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">{t(`WEBSITE_TEXT_IVAO_VID`)}</label>
                        </div>
                        <button disabled={islogging} type="submit" className="flex items-center justify-center text-white bg-[#002A95] font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center">
                            
                            {
                                islogging ?
                                
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>

                                :

                                    <>{t(`WEBSITE_TEXT_REGISTER`)}</>
                            }
                            
                        </button>
                    </form>

                    <div className="flex items-center justify-between">

                        <div className="text-sm mt-4">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            {t(`WEBSITE_TEXT_FORGOT_YOUR_PASSWORD`)}?
                        </a>
                        </div>
                    </div>

                </div>
                </div>
            </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://virtuallh.com/images/welcome-background.png"
                alt="login_bg"
            />
            </div>
      </div></div>

    )

}

export default Register;