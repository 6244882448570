//import { AXIOS, /*CLIENT_ID, CLIENT_SECRET*/ } from '../services/Base'

import axios from 'axios'

import { URL_API } from '../services/Base';

interface Credentials {
    client_id: number
    client_secret: string
    username: string
    password: string
    grant_type: string
}

interface Response {
    access_token: string;
    user: {
        name: string;
        email: string,
        profile_photo_url: string,
        id_cliente: number,
        id_usuario: number,
        role_id: number,
        cliente: {
            onboarding: number,
            cpf_cnpj: string,
            st_nome_cliente: string,
            id_cliente: number,
            numero_usuarios: number,
            hash_cadastro: string,
            credito_contratado: number,
            credito_saldo: number
        }
    }    
    error: string,
    message: string
}

interface getCredentials {

}

const logOff = () => {

    localStorage.setItem('@oauth:user', '');
    localStorage.setItem('@oauth:token', '');
    localStorage.setItem('@oauth:modules', '');
    window.location.reload()

}

//export default async function useApi(method: string, credentials: Credentials, path: string) {
export default async function useApi(method: string, credentials: Credentials, path: string): Promise<Response> {
        
    try {

        const response = await axios.post(`${URL_API}${path}`, credentials)

        console.log('Inside post function > ' , response.data)

        return response.data

    } catch (error: any) {

        console.log('error inside useApi > ', error.response.status)

        if(error.response.status === 401) {

            logOff()
            
        }

        return error

    }
    
}

export async function useApiGet(method: string, getData: getCredentials, path: string) {
        
    try {

        const response = await axios.get(`${URL_API}${path}`, {
            params: getData
        })

        console.log('response inside useApi > ', response)

        return response.data

    } catch (error: any) {

        console.log('error inside useApi > ', error.response.status)

        if(error.response.status === 401) {

            logOff()
            
        }

        return error

    }

}

export async function useApiPost(method: string, postData: getCredentials, path: string) {
        
    try {

        const response = await axios.post(`${URL_API}${path}`, postData)

        /*
            const response = await axios.post(`${URL_API}${path}`, 
                postData
            )
        */

        console.log('response inside useApi > ', response)

        return response.data

    } catch (error: any) {

        console.log('error inside useApi > ', error.response.status)

        if(error.response.status === 401) {

            logOff()
            
        }

        return error

    }

}

export async function useApiPut(method: string, putData: getCredentials, path: string) {
        
    try {

        const response = await axios.put(`${URL_API}${path}`, 
            putData
        )

        console.log('response inside useApi PUT > ', response)

        return response.data

    } catch (error: any) {

        console.log('error inside useApi > ', error.response.status)

        if(error.response.status === 401) {

            logOff()
            
        }

        return error

    }

}

export async function useApiDelete(method: string, deleteData: getCredentials, path: string) {
        
    try {

        const response = await axios.delete(`${URL_API}${path}`, {
            params: deleteData
        })

        console.log('response inside useApi > ', response)

        return response.data

    } catch (error: any) {

        console.log('error inside useApi > ', error.response.status)

        if(error.response.status === 401) {

            logOff()
            
        }

        return error

    }

}

/*
import axios from 'axios'

export const URL_API = "https://apihomolog.simbiose.tech/api/v1/";

const api = axios.create({
    baseURL: URL_API
})

export default api;
*/