import React, { createContext, useState, useEffect } from 'react'
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import axios from 'axios'
import { useApiPost } from '../../hooks/useApi';

interface User {
    gvauser_id: number
    name: string
    surname: string
    callsign: string
    email: string
    user_type_id: number
    register_date: string
    last_visit_date: string
    ivaovid: number
    hub_id: number
    location: string
    country: string
    city: string
    ivao_hours: number
    gva_hours: number
    rank: RankTypes
    route_id: number
    rank_id: number
    money: number
    birth_date: string
    vatsimid: number
    language: string
    transfered_hours: number
    accept_emails: boolean
    pilot_image: string
    streamer: string
    link: string
    pilotofmonth: boolean
}

export interface RankTypes {
    id: number
    image_url: string
    maximum_hours: number
    minimum_hours: number
    rank: string
    salary_hour: number
}

interface AuthContextData {
    signed: boolean;
    user: User | null;
    islogging: boolean
    DoLogin: Function
    signOut: Function
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({children}) => {

    const { t } = useTranslation()

    const [ user, setUser ] = useState<User | null>(null)

    const { addToast } = useToasts()

    const [ islogging, setIsLogging ] = useState<boolean>(false)

    const setAxiosHeaders = (token: string) => {

        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.defaults.headers.common['Accept'] = `application/json`
        axios.defaults.headers.common['Content-Type'] = `application/json`

    }

    useEffect(() => {

        setIsLogging(true)    

        async function loadStorageData() {
            
            const storagedUser = localStorage.getItem('@oauth:user');
            const storagedToken = localStorage.getItem('@oauth:token');
            const modulos = JSON.parse(localStorage.getItem('@oauth:modules') || `{}`);

            if(storagedUser && storagedToken && modulos) {

                setUser(JSON.parse(storagedUser))
                setAxiosHeaders(storagedToken)

            }

            setIsLogging(false)    

        }

        loadStorageData();

    }, [])

    const DoLogin = async (e: React.FormEvent, username: string, password: string) => {

        setIsLogging(true)

        e.preventDefault()

        const method = 'POST'

        const postData = {
            username: username,
            password: password
        }  

        const path = `login`

        useApiPost(method, postData, path).then( (response) => {
                
                localStorage.setItem('@oauth:user', JSON.stringify(response.user));
                localStorage.setItem('@oauth:token', response.token); 
                setUser(response.user)
                setAxiosHeaders(response.token)    
                setIsLogging(false)               
                addToast(`${t(`WELCOME_TO_VIRTUALLH`)}`, { appearance: 'success', autoDismiss: true });

            }

        ).catch( (error) => {

                setIsLogging(false)

                console.log(error)

            }

        )   

    }

    function signOut() {

        setUser(null)
        localStorage.setItem('@oauth:user', '');
        localStorage.setItem('@oauth:token', '');
        localStorage.setItem('@oauth:modules', '');

    }
    
    return (
        <AuthContext.Provider value={{
            signed: !!user,
            DoLogin, 
            user, 
            islogging, 
            signOut
        }}>

            {children}

        </AuthContext.Provider>

    )

}

export default AuthContext;