import React from 'react'

    const Flight: React.FC = () => {

        return (

            <div className="relative mb-6 box-border h-[100px] w-[full] p-4 border-2 rounded border-l-4 border-l-[#00235F]">

                <div className="absolute flex top-2 left-2 font-black">

                    <div>ICON</div>

                    <div className="FlightNumber ml-2">DLHXXX</div>

                </div>

            </div>

        )

    }

export default Flight;