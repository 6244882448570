export const enUS = {
    "language": "English",

    "WEBSITE_TEXT_HEADER_PRESENTATION": "The virtual airline you’ll love flying with",
    "WELCOME_TO_VIRTUALLH": "Welcome to Virtual LH",
    "WEBSITE_TEXT_NAME": "Name",
    "WEBSITE_TEXT_SURNAME": "Surname",
    "WEBSITE_TEXT_EMAIL": "Email",
    "WEBSITE_TEXT_DATE_OF_BIRTH": "Date of birth",
    "WEBSITE_TEXT_COUNTRY": "Country",
    "WEBSITE_TEXT_CITY": "City",
    "WEBSITE_TEXT_PREFERRED_AIRLINE": "Preferred airline",
    "WEBSITE_TEXT_VATSIM_ID": "Vatsim ID",
    "WEBSITE_TEXT_IVAO_VID": "IVAO VID",
    "WEBSITE_TEXT_FORGOT_YOUR_PASSWORD": "Forgot your password",
    "WEBSITE_TEXT_REGISTER": "Register"
}