import React from 'react'
import Image from '../../../assets/image/Image 2.png'

const AirlineInformation: React.FC = () => {

    const vaInformation = `<p class="mt-[36px]">Created in 2013, Virtual LH is committed to being a friendly and fun community for beginner and advanced flight simulation pilots.</p> <p class="mt-[36px]">Our timetable contains 45,000+ flights drawn from the real-world routes based on a popular German Group airline and its Star Alliance partners.</p> <p class="mt-[36px]">We have 6,000+ airplanes in our database which represents the active airplanes flying for our 70+ airlines in real life.</p> <p class="mt-[36px]">Using our Web Interface and Custom VLHACARS software, your flight experience is logged and reported. The website will keep a record of your flights for your log book and as your experience grows so does your access to the larger fleet and higher pilot ranks.</p> <p class="mt-[36px]">VirtualLH has a <a href="https://discord.gg/4kv8k5E" target="_blank" style="color: #0A1D3D; font-weight: bold">Discord community</a> where pilots can chat about current sim events as well as troubleshooting and learning. We offer Tours where you can take an airplane on a set number of flights starting at one of our hubs and going all around the world to discover our timetable. Virtual LH Pilots also come together and participate in Events online with VATSIM and IVAO.</p> <p class="mt-[36px]">Come fly with us today and see for yourself - There’s no better way to fly</p> <p class="mt-[36px]">Welcome Aboard!</p>` 

    return (

        <div className="flex flex-row justify-center pt-[40px] pb-[100px]">
            <div className='w-screen max-w-[1100px] flex'>
                <div className="w-full flex justify-center flex-col pl-[20px] font-sans text-[#707070]">                
                    <h2 className="text-[60px] text-center font-['inter'] text-[#1B1B1D] font-thin drop-shadow-xl">We know <span className="underline decoration-[#002A95]">how to do</span></h2>  
                    <h2 className="text-[34px] drop-shadow-xl text-center text-[#707070]">Non-stopping you since 2013</h2>
                    <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#707070]" dangerouslySetInnerHTML={{ __html: vaInformation }} />
                </div>
            </div>
        </div>

    )

}

export default AirlineInformation