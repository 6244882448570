import React from 'react'
import {
  Routes,
  Route,
} from "react-router-dom";
import Homepage from '../pages/auth/homepage/Homepage';
import Login from '../pages/auth/login/Login';
import Register from '../pages/auth/register/Register';

const AuthRoutes: React.FC = () => {

    return (
      <div className="App">      
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </div>

    )

}

export default AuthRoutes;