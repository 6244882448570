import React from 'react'

    const Profile: React.FC = () => {

        return (

            <div className="px-4 mt-6 sm:px-6 lg:px-8">Pilot Profile here</div>

        )

    }

export default Profile;