import React from 'react'

import VLH from '../../../../assets/tails/lh-tail.png'
import EWG from '../../../../assets/tails/ewg-tail.png'
import SWR from '../../../../assets/tails/swr-tail.png'
import BEL from '../../../../assets/tails/bel-tail.png'
import ACA from '../../../../assets/tails/aca-tail.png'
import AUA from '../../../../assets/tails/aua-tail.png'
import STAR from '../../../../assets/tails/star-tail.png'
import { Link } from 'react-router-dom'

const StartFlyingUs: React.FC = () => {

    return (

        <div className="flex flex-row justify-center">
            <div className='w-full max-w-[75%] flex pb-[64px] pt-[64px]'>
                <div className="w-full flex flex-col items-center pl-[20px] font-sans text-[#707070]">
                    <h2 className="text-[60px] text-[#0A1D3D] text-center">Start flying the dark blue skies with VA_NAME_HERE</h2>      
                    <h2 className="text-[34px] drop-shadow-xl text-center text-[#707070]">70+ airlines to enjoy!</h2>

                    <div className="flex pt-[40px]">
                        <div className="z-[10] w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover hover:rotate-45 duration-150" style={{backgroundImage: `url(${VLH})` }}></div>
                        <div className="z-[9] w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover hover:rotate-45 duration-150" style={{backgroundImage: `url(${EWG})` }}></div>
                        <div className="z-[8] w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover hover:rotate-45 duration-150" style={{backgroundImage: `url(${SWR})` }}></div>
                        <div className="z-[7] w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover hover:rotate-45 duration-150" style={{backgroundImage: `url(${BEL})` }}></div>
                        <div className="z-[6] w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover hover:rotate-45 duration-150" style={{backgroundImage: `url(${ACA})` }}></div>
                        <div className="z-[5] w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover hover:rotate-45 duration-150" style={{backgroundImage: `url(${AUA})` }}></div>
                        <div className="z-0 w-16 h-16 rounded-full flex items-center justify-center shadow-lg -ml-4 bg-cover bg-[#fafafa] hover:rotate-45 duration-150" style={{backgroundImage: `url(${STAR})` }}></div>
                        
                    </div>

                    <Link to={`/register`}>
                        <button className="bg-[#0A1D3D] text-white mt-[50px] font-bold py-[17px] px-[60px] rounded inline-flex items-center">
                            <span>APPLY NOW</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </button>
                    </Link>
                </div>
            </div>
        </div>

    )

}

export default StartFlyingUs