export const ptBR = {
    "language": "Português",

    "WEBSITE_TEXT_HEADER_PRESENTATION": "A aerea virtual que você vai amar particiar",
    "WELCOME_TO_VIRTUALLH": "Bem vindo ao Virtual LH",
    "WEBSITE_TEXT_NAME": "Nome",
    "WEBSITE_TEXT_SURNAME": "Sobrenome",
    "WEBSITE_TEXT_EMAIL": "Email",
    "WEBSITE_TEXT_DATE_OF_BIRTH": "Data de nascimento",
    "WEBSITE_TEXT_COUNTRY": "País",
    "WEBSITE_TEXT_CITY": "Cidade",
    "WEBSITE_TEXT_PREFERRED_AIRLINE": "Empresa de preferência",
    "WEBSITE_TEXT_VATSIM_ID": "ID Vatsim",
    "WEBSITE_TEXT_IVAO_VID": "VID IVAO",
    "WEBSITE_TEXT_FORGOT_YOUR_PASSWORD": "Esqueceu sua senha",
    "WEBSITE_TEXT_REGISTER": "Registrar"
}