import React, { useContext, useState } from 'react'
import '../../../styling/Login.scss'
import { Link } from 'react-router-dom'
import AuthContext from '../../../context/Auth/Auth'

const Login: React.FC = () => {

    const [ username, setUsername ] = useState<string>("")

    const [ password, setPassword ] = useState<string>("")

    const { DoLogin, islogging } = useContext(AuthContext)

    return (
        <div className="h-screen">
        <div className="min-h-full flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 lg:min-w-[500px]">
            <div className="mx-auto w-full max-w-sm lg:w-96">
                <div className="flex justify-center">
                    <Link to={`/`}>
                        <img
                            className="h-12 w-auto"
                            src="https://virtuallh.com/vam/images/logo/dlh.png"
                            alt="login_logo"
                        />
                    </Link>
                </div>
                <h2 className="mt-10 text-[34px] font-thin text-black text-center">Welcome back</h2>
                <p className="mt-2 text-[16px] font-thin text-[#636368] text-center">Enter your credentials bellow</p>

                <div className="mt-8">

                <div className="mt-6">
                    <form onSubmit={(e: React.SyntheticEvent) => DoLogin(e, username, password)}>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="text" name="floating_email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" value={username} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)} placeholder=" " />
                            <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address or Pilot ID</label>
                        </div>
                        <div className="relative z-0 w-full mb-6 group">
                            <input type="password" name="floating_password" id="floating_password" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " value={password} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)} />
                            <label  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password</label>
                        </div>
                        <button disabled={islogging} type="submit" className="flex items-center justify-center text-white bg-[#002A95] font-medium rounded-lg text-sm w-full px-5 py-2.5 text-center">
                            
                            {
                                islogging ?
                                
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>

                                :

                                    <>Log in</>
                            }
                            
                        </button>
                    </form>

                    <div className="flex items-center justify-between">

                        <div className="text-sm mt-4">
                        <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                            Forgot your password?
                        </a>
                        </div>
                    </div>

                    <p className="mt-6 text-[#636368] text-[12px] text-center cursor-pointer hover:underline"><Link to={'/register'}>Not a member? Sign up</Link></p>
                </div>
                </div>
            </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
            <img
                className="absolute inset-0 h-full w-full object-cover"
                src="https://virtuallh.com/images/welcome-background.png"
                alt="login_bg"
            />
            </div>
      </div></div>

    )

}

export default Login;