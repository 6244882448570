import React from 'react'

const Features: React.FC = () => {

    return (

        <div className="bg-[#f8f9fa] pt-[100px] pb-[100px]">

            <div className="container mx-auto max-w-[1100px] snap-center">
                
                <h2 className="text-[60px] font-['inter'] text-[#1B1B1D] font-thin drop-shadow-xl text-center">An experience like nothing else</h2>
                
                <h2 className="text-[34px] drop-shadow-xl text-center text-[#707070]">There is something for everyone</h2>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-[50px]">
                    
                    <div className="box-border h-[230px] w-[100%] p-5 bg-[#F3F3F6] rounded-[6px]">
                        
                        <div className="icon text-[#002A95]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>

                        <h2 className="title font-['inter'] text-[#1B1B1D] mt-[10px] text-[28px] font-black">Growing every day</h2>

                        <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#636368] font-light">VirtualLH is used by 250+ users every day.</p>
                        
                    </div>
                    
                    <div className="box-border h-[230px] w-[100%] p-5 bg-[#F3F3F6] rounded-[6px]">
                        
                        <div className="icon text-[#002A95]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                            </svg>
                        </div>

                        <h2 className="title font-['inter'] text-[#1B1B1D] mt-[10px] text-[28px] font-black">All custom design</h2>

                        <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#636368]">Custom built ACARS and website software.</p>
                        
                    </div>
                    
                    <div className="box-border h-[230px] w-[100%] p-5 bg-[#F3F3F6] rounded-[6px]">
                        
                        <div className="icon text-[#002A95]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>
                        </div>

                        <h2 className="title font-['inter'] text-[#1B1B1D] mt-[10px] text-[28px] font-black">Global route coverage</h2>

                        <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#636368]">More than 70,000+ real world routes.</p>
                        
                    </div>
                    
                    <div className="box-border h-[230px] w-[100%] p-5 bg-[#F3F3F6] rounded-[6px]">
                        
                        <div className="icon text-[#002A95]">                            
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7" />
                            </svg>
                        </div>

                        <h2 className="title font-['inter'] text-[#1B1B1D] mt-[10px] text-[28px] font-black">Huge fleet of aircraft</h2>

                        <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#636368]">Whatever your favourite aircraft is, we have it.</p>
                        
                    </div>
                    
                    <div className="box-border h-[230px] w-[100%] p-5 bg-[#F3F3F6] rounded-[6px]">
                        
                        <div className="icon text-[#002A95]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
                            </svg>
                        </div>

                        <h2 className="title font-['inter'] text-[#1B1B1D] mt-[10px] text-[28px] font-black">Tours, tours, tours</h2>

                        <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#636368]">Take a look at our current tours here. Always fresh.</p>
                        
                    </div>
                    
                    <div className="box-border h-[230px] w-[100%] p-5 bg-[#F3F3F6] rounded-[6px]">
                        
                        <div className="icon text-[#002A95]">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                            </svg>
                        </div>

                        <h2 className="title font-['inter'] text-[#1B1B1D] mt-[10px] text-[28px] font-black">Cargo? Sure</h2>

                        <p className="subtitle font-['inter'] mt-[5px] text-[24px] text-[#636368]">Aside from commercial flights, we do cargo too.</p>
                        
                    </div>

                </div>

            </div>

        </div>

    )

}

export default Features