import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import { ptBR } from './assets/locales/pt-BR'
import { enUS } from './assets/locales/en-US'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    pt: {
      translation: ptBR
    },
    en: {
      translation: enUS
    }
};

i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .init({
    resources,
    //lng: 'en',
    keySeparator: false, 
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;