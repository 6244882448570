import React from 'react'

const LiveMap: React.FC = () => {

    return (

        <div className="flex flex-col justify-center pt-[100px]">
                
            <h2 className="text-[60px] font-['inter'] text-[#1B1B1D] drop-shadow-xl font-thin text-center">Live VA_ICAO_HERE Flights</h2>            
            <h2 className="text-[34px] drop-shadow-xl text-center text-[#707070]">Check our active flights</h2>
            <div className="flex mt-[50px]">
                <div className="container max-w-full pb-[40px]">
                    <iframe className="h-[500px] w-full" src="https://livemap.pages.dev/?source=https://virtuallh.com"></iframe>
                </div>
            </div>

        </div>

    )

}

export default LiveMap;