import React from 'react'
import Navbar from '../components/Navbar'
import AirlineInformation from './components/AirlineInformation'
import Features from './components/Features'
import Footer from './components/Footer'
import Header from './components/Header'
import LiveMap from './components/LiveMap'
import StartFlyingUs from './components/StartFlyingUs'
import Tours from './components/Tours'

const Homepage: React.FC = () => {

    return (

        <>
  
            <Navbar />

            <Header />

            <AirlineInformation />

            <Features />

            <LiveMap />

            <Tours />

            <StartFlyingUs />

            <Footer />

        </>

    )

}

export default Homepage