import React from 'react'
import Image from '../../../../assets/image/Image 2.png'

const Tours: React.FC = () => {

    return (

        <div className="flex flex-row justify-center pt-[40px]">
            <div className='w-full max-w-[75%] flex pb-[40px] border-b-[0.4px] border-[#243c5a]'>
                <div className="w-full flex justify-center flex-col pl-[20px] font-sans text-[#707070]">
                    <h2 className="text-[40px]">Our active tours</h2>
                    <p>aaa</p>
                    <p>bbb</p>
                </div>
                <div className="w-full flex justify-end">
                    <img alt="tour_banner_tours" src={Image} />
                </div>
            </div>
        </div>

    )

}

export default Tours