import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import HeaderImage from '../../../../assets/image/header-image.png'

const Header: React.FC = () => {

    const { t } = useTranslation()

    return (

        <div className={`h-screen bg-no-repeat bg-cover relative bg-top -top-20 min-h-[200px]`} style={{backgroundImage: `url(${HeaderImage})`}}>

            <div className="container mx-auto font-['inter'] max-w-[693px] h-full snap-center flex flex-col items-center justify-center">
                
                <h1 className="text-[60px] text-white drop-shadow-xl font-black text-center">{t(`WEBSITE_TEXT_HEADER_PRESENTATION`)}</h1>
                
                <h2 className="text-[34px] text-white drop-shadow-xl mt-10 text-center">VA_NAME_HERE is one of the best virtual airlines with thousands of real world routes</h2>
                
                <Link to={`/register`}>
                    <button className="bg-[#002A95] text-white w-[185px] h-[49px] mt-[30px] cursor-pointer font-black rounded">
                        GET STARTED
                    </button>
                </Link>

            </div>

        </div>

    )

}

export default Header;