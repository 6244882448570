import React, { useContext } from 'react'
import AuthContext from '../context/Auth/Auth';
import AppNavbar from '../pages/app/components/appNavbar';
import AppRoutes from './App.routes';
import AuthRoutes from './Auth.routes';

const Routes: React.FC = () => {

    const { signed, islogging } = useContext(AuthContext)

    return (

        signed && !islogging ?

            <>

                <AppNavbar />

                <AppRoutes />
            
            </>

        :

            <AuthRoutes />

    )

}

export default Routes;