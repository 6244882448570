import React from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import Routes from './routes/Routes'
import { AuthProvider } from './context/Auth/Auth'
import './index.css'

interface AppProps {

}

const App: React.FC<AppProps> = () => {
  return (

    <AuthProvider>

      <Router>

        <Routes />

      </Router>
    
    </AuthProvider>

  );
}

export default App;
