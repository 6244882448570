import React from 'react'

const Footer: React.FC = () => {

    const year = new Date().getFullYear();

    return (

        <div className="flex flex-row justify-center bg-[#000000] h-[86px]">
            <div className='w-full max-w-[75%] flex'>
                <div className="w-full flex justify-between items-center pl-[20px] font-sans text-[#707070]">
                    <div>
                        <h2 className="text-[20px] text-[#3B3B3B] hover:text-white">Copyright © {year} VA_NAME_HERE</h2>
                    </div>
                    <div className="flex">
                        <a className="text-[20px] text-[#3B3B3B] hover:text-white ml-[15px]" href="https://www.facebook.com/virtuallh">vLH on Facebook</a>
                        <a className="text-[20px] text-[#3B3B3B] hover:text-white ml-[15px]" href="https://www.youtube.com/channel/UCAWjkwBCuEuUzXmzvZyP4iA">vLH on Youtube</a>
                        <a className="text-[20px] text-[#3B3B3B] hover:text-white ml-[15px]" href="https://lufthansa.com/">Lufthansa</a>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default Footer